import * as React from 'react';
import './TasksArchive.css';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Moment from 'moment-timezone'

import * as taskController from '../task/controller'
import {Task} from '../../_models/Task'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { toast } from 'react-toastify';

import  socket  from "../../socket";

import {customSelectStyles, themeStyles} from '../task/themeStyles.js'

export default class TasksArchive extends React.Component<any, any> {
  removeTask: any
  constructor(props: any) {
    super(props);
    this.state = {
      tasks: [],
      providers: [],
      clients: [],
      searchTerm: {
        provider: null,
        client: null,
        des: null,
        startDate: null,
        endDate: null
      }
    }
    this.loadTasks = this.loadTasks.bind(this)
    this.removeTask = taskController.removeTask.bind(this)
    this.search = this.search.bind(this)
    this.actualSearch = this.actualSearch.bind(this)
    this.updateSearchTerm = this.updateSearchTerm.bind(this)
  }


  loadTasks(getAll:boolean = false) {
    if(getAll || !this.props.location.search) {
      fetch('tasks/getAll', {
        method: 'POST',
        body: JSON.stringify({archived: true})
      }).then(results => {
        return results.json();
      }).then(data => {
          let tasks:any[] = []
          data.tasks.map((task: any) => {
            task = new Task(task)

            tasks.push(task)
          })
          this.setState({tasks})

      }).catch(err => {
        toast.error(err.message);
      })
    } else {
      this.updateSearchTerm(this.props)
    }

  }

  loadClients() {
    return fetch('clients/getAll', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
      let clients = data.clients
      clients = clients.map((client: any) => {
        return {value: client.id, label: client.name}
      })
      return this.setState({clients}, () => {
        return Promise.resolve()
      })
    }).catch(err => {
      toast.error(err.message);
    })
  }

  loadProviders() {
    return fetch('providers/getAll', {
      method: 'POST'
    }).then(results => {
      return results.json();
    }).then(data => {
        let providers = data.providers
        providers = providers.map((provider: any) => {
          return {value: provider.id, label: provider.name}
        })
        return this.setState({providers}, () => {
          return Promise.resolve();
        })
    }).catch(err => {
      toast.error(err.message);
    })
  }


  actualSearch() {
    let term = this.state.searchTerm
    fetch('tasks/search', {
      method: 'POST',
      body: JSON.stringify(term)
    }).then(results => {
      return results.json();
    }).then(data => {
        let tasks:any[] = []
        data.tasks.map((task: any) => {
          task = new Task(task)
          tasks.push(task)
        })
        this.setState({tasks})

    }).catch(err => {
      toast.error(err.message);
    })
  }

  updateSearchTerm(props: any) {
    let query = props.location.search.substring(1).split("&")
    let searchTerm = this.state.searchTerm
    let search = false
    query.map((entry: any) => {
      let o = entry.split("=")
      if(o && o[0] && o[1]) {
        o[1] = o[1].length > 0? o[1] : null

        if (o[0] == "startDate" || o[0] == "endDate" || o[0] == "des") {
          o[1] = decodeURIComponent(o[1])
        } else if(o[0] == "client" || o[0] == "provider") {
          o[1] = Number.isInteger(parseInt(o[1]))? parseInt(o[1]) : null
        }
        o[1] = o[1] === "true"? true : o[1]
        o[1] = o[1] === "false"? false : o[1]
        o[1] = o[1] === "null"? null : o[1]
        if(searchTerm[o[0]] !== undefined) {
          searchTerm[o[0]] = o[1]
          search = true
        }
      }


    })
    if(search) {
      this.setState({searchTerm}, () => {
        this.actualSearch()
      })
    }

  }

  componentDidMount() {
    socket.on('task_delete', (data:any) => {
      let taskId = data.id
      let tasks = this.state.tasks.filter((obj: any) => {
        return obj.id != taskId //Removing the task from the array
      })
      this.setState({tasks})
    });

  }

  search(event: any) {
    event.preventDefault()
    this.props.history.push({
           pathname: '/tasks/archive/',
           search: "?" + new URLSearchParams(this.state.searchTerm).toString()
    })
    this.actualSearch()
  }

  componentWillMount() {
    this.loadTasks()
    this.loadProviders()
    this.loadClients()
    socket.connect()
  }

  componentWillUnmount() {
    socket.removeAllListeners()
    socket.disconnect()
  }

  handleSelectChange(type: string, selectedOption:any) {
    let searchTerm = this.state.searchTerm
    searchTerm[type] = selectedOption.value
    this.setState({searchTerm})
  }

  handleSearchChange(event: any) {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    let name = event.target.name
    let searchTerm = this.state.searchTerm
    searchTerm[name] = value
    this.setState({searchTerm})
  }

  handleDateChange(this:any, type: string, date:any) {
    let searchTerm = this.state.searchTerm
    searchTerm[type] = Moment(date).format("MM/DD/YYYY")
    this.setState({searchTerm})
  }


  public render() {
    const columns = [{
      Header: '#',
      accessor: 'id',
      maxWidth: 80,
      resizable: false
    }, {
      id: 'provider',
      Header: 'Provider',
      maxWidth: 120,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>{row.value.provider? row.value.provider.name : ""}</div>
    }, {
      id: 'client',
      Header: 'Client',
      maxWidth: 160,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>{row.value.client? row.value.client.name : ""}</div>
    }, {
      Header: 'Date',
      accessor: 'date',
      maxWidth: 140,
    }, {
      Header: 'Start Time',
      maxWidth: 120,
      accessor: 'startTime'
    }, {
      Header: 'End Time',
      maxWidth: 120,
      accessor: 'endTime'
    }, {
      Header: 'P.O.',
      accessor: 'po'
    }, {
      id: 'exported',
      Header: 'QB?',
      maxWidth: 80,
      sortable: true,
      sortMethod: (a:any, b:any) => {
        return (a.exported === b.exported)? 0 : a.exported? -1 : 1;
      },
      resizable: false,
      accessor: (d:any) => d,
      Cell: (row:any) => <div className={row.value.exported? "circle green" : "circle red"}></div>
    }, {
      id: 'settings',
      Header: 'Settings',
      sortable: false,
      maxWidth: 100,
      resizable: false,
      accessor: (d:any) => d,
      Cell: (row:any) => <div>
        <a onClick={() => {
          let searchTerm = this.state.searchTerm
          if(!searchTerm.des && !searchTerm.provider && !searchTerm.client && !searchTerm.startDate && !searchTerm.endDate) {
            this.props.history.push('/tasks/archive/'+row.value.id)
          } else {
            this.props.history.push({
                   pathname: '/tasks/archive/'+row.value.id,
                   search: "?" + new URLSearchParams(this.state.searchTerm).toString()
            })
          }

        }}><i className="fas fa-edit"></i></a>
        <i onClick={taskController.removeTask.bind(this, row.value.id)} className="fas fa-trash-alt"></i>
      </div>
    }]
    let client = this.state.searchTerm.client? this.state.clients.filter((c:any) => {return this.state.searchTerm.client == c.value}).pop() : null
    let provider = this.state.searchTerm.provider? this.state.providers.filter((c:any) => {return this.state.searchTerm.provider == c.value}).pop() : null

    return (
      <div id="tasks-archive-root">

      <div className="searchConsole">
        <div className="third">
        <label>P.O or Description</label>
        <input type="text" name="des" value={this.state.searchTerm.des? this.state.searchTerm.des : ""} onChange={this.handleSearchChange.bind(this)} /></div>
        <div className="third">
        <label>Start Date</label>
        <DatePicker
          selected={this.state.searchTerm.startDate? Moment(this.state.searchTerm.startDate, "MM/DD/YYYY").toDate() : null}
          onChange={this.handleDateChange.bind(this, "startDate")}
          autoComplete="disable_autocomplete"
          name="startDate"
          readOnly={window.innerWidth <= 768}
        /></div>
        <div className="third">
        <label>End Date</label>
        <DatePicker
          selected={this.state.searchTerm.endDate? Moment(this.state.searchTerm.endDate, "MM/DD/YYYY").toDate() : null}
          onChange={this.handleDateChange.bind(this, "endDate")}
          autoComplete="disable_autocomplete"
          name="endDate"
          readOnly={window.innerWidth <= 768}
        /></div>
        <div className="third">
        <label>Client</label>
        <Select
          styles={customSelectStyles}
          value={client}
          onChange={this.handleSelectChange.bind(this, "client")}
          options={this.state.clients}
          theme={themeStyles}
          className={"multiSelect searchSelect"}
          classNamePrefix="react-select"
          menuPlacement={"bottom"}
          placeholder={"Filter by Client"}
        /></div>
        <div className="third">
        <label>Provider</label>
        <Select
          styles={customSelectStyles}
          value={provider}
          onChange={this.handleSelectChange.bind(this, "provider")}
          options={this.state.providers}
          theme={themeStyles}
          className={"multiSelect searchSelect"}
          classNamePrefix="react-select"
          menuPlacement={"bottom"}
          placeholder={"Filter by Provider"}
        /></div>
        <div className="third search">
        <button style={{width: "49%", float: "right"}}className="second-btn" onClick={() => {
          this.setState({searchTerm: {
            provider: null,
            client: null,
            des: null,
            startDate: null,
            endDate: null
          }}, () => {
            this.props.history.push("/tasks/archive")
            this.loadTasks(true)
          })
        }}>Clear</button>
          <button style={{width: "49%", float: "left"}}className="primary-btn" onClick={this.search.bind(this)}>Search</button>
        </div>

        <div className="clearfix"></div>
      </div>

      <div className="board">
        <ReactTable
          data={this.state.tasks}
          columns={columns}
          noDataText="No tasks found"
          pageSizeOptions={[100,300,500,1000]}
          defaultPageSize={100}
          style={{
            height: "calc(100vh - 285px)",
            textAlign: "center",
            fontSize: "1em",
            position: "centner",
            right: "0",
            left: "0",
            margin: "0 auto"
          }}
          className="-striped -highlight reactTable"
        />
      </div>


      </div>
    )
  }

}
