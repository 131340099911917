import {Task} from '../../_models/Task'
import {ClientTask} from '../../_models/ClientTask'
import { toast } from 'react-toastify';

export function loadExpenses(this:any, id:number) {
  return fetch(`tasks/${id}/expenses`, {
      method: "GET"
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        this.setState({ expenses: data.expenses });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }


  export function loadImages(this:any, id:number) {
    return fetch(`tasks/${id}/images`, {
      method: "GET"
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        this.setState({ images: data.images });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  export function loadSignatures(this:any, id:number) {
    return fetch(`tasks/${id}/signatures`, {
      method: "GET"
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        this.setState({ signatures: data.signatures });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }


  export function loadLocations(this:any, id:number) {
    return fetch(`tasks/${id}/locations`, {
      method: "GET"
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        this.setState({ locations: data.locations });
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, data.locations);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  export function loadClientTask(this:any, id:number) {
    return fetch(`tasks/${id}/clientTask`, {
      method: "GET"
    })
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        if(data.clientTask) {
          const clientTask = new ClientTask(data.clientTask)
          this.setState({ clientTask });
        }

      })
      .catch((err) => {
        //toast.error(err.message);
        console.log(err)
      });
  }



export function loadTask(this: any, id:any, officeTask: boolean) {
    fetch('tasks/get', {
      method: 'POST',
      body: JSON.stringify({id})
    }).then(results => {
      return results.json();
    }).then(data => {
        let task = new Task(data.task)
        //create new one from the office task
        this.setState({task}, async () => {
          await this.loadExpenses(this.props.match.params.id)
          await this.loadImages(this.props.match.params.id)
          await this.loadSignatures(this.props.match.params.id)
          await this.loadLocations(this.props.match.params.id)

          const clientTask = new ClientTask(data.task)
          delete clientTask.id //it's a new clientTask
          clientTask.locations = this.state.locations.map((location: any) => {
            let tLocation = Object.assign({}, location)
            delete tLocation.id
            return tLocation
          })
          clientTask.signatures = this.state.signatures.map((signature: any) => {
            let tSignature = Object.assign({}, signature)
            delete tSignature.id
            return tSignature
          })
          clientTask.images = this.state.images.map((image: any) => {
            let tImage = Object.assign({}, image)
            delete tImage.id
            return tImage
          })
          clientTask.expenses = this.state.expenses.map((expense: any) => {
            let tExpense = Object.assign({}, expense)
            delete tExpense.id
            return tExpense
          })
          this.setState({clientTask}, () => {
            this.loadClientTask(id);

          })

        })
    }).catch(err => {
      toast.error(err.message);
    })
  }

  export function loadTaskFromToken(this: any, token:string) {
      fetch('public/getClientTask', {
        method: 'POST',
        body: JSON.stringify({token})
      }).then(results => {
        return results.json();
      }).then(data => {
          let clientTask = new ClientTask(data.clientTask)
          this.setState({clientTask: clientTask})
          this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, clientTask);
      }).catch(err => {
        toast.error(err.message);
      })
    }

export function calculateAndDisplayRoute(this: any, directionsService: any, directionsDisplay: any, locations: any) {
   if(!locations || locations.length < 1) {
     this.directionsDisplay.setMap(null);
     if(this.marker) this.marker.setMap(null)
     return
   } else if(locations.length < 2 && locations.length > 0) {
     this.directionsDisplay.setMap(null);
     this.marker = new window.google.maps.Marker({
             position: {lat: locations[0].lat, lng: locations[0].lng},
             map: this.map
           });
    this.map.setCenter(this.marker.getPosition())
    this.map.setZoom(12)
    return
   }

   if(this.marker) this.marker.setMap(null)
   this.directionsDisplay.setMap(this.map);

   var waypts: any[] = [];
   for (var i = 1; i < locations.length-1; i++) {
     waypts.push({
       location: locations[i].address,
       stopover: true
     });
   }

   directionsService.route({
     origin: locations[0].address,
     destination: locations[locations.length-1].address,
     waypoints: waypts,
     optimizeWaypoints: false,
     travelMode: 'DRIVING'
   }, (response: any, status: any) => {
     if (status === 'OK') {
       directionsDisplay.setDirections(response);
       this.setState({locations})
     } else {
       toast.error('Directions request failed due to ' + status);
     }
   });

}

export function loadMap(this: any, officeTask: boolean) {
  let google = window.google
  this.directionsService = new google.maps.DirectionsService;
  this.directionsDisplay = new google.maps.DirectionsRenderer;
  this.map = new google.maps.Map(document.getElementById('map'), {
    zoom: 8,
    mapTypeControl: false,
    streetViewControl: false,
    center: {lat: 40.712776, lng: -74.005974} //New York Center
  });

  if(this.props.match) {
    if(this.props.match.params.id) {
      this.loadTask(this.props.match.params.id, officeTask)
    } else if(this.props.match.params.token) {
      this.loadTaskFromToken(this.props.match.params.token)
    }
  }
}


export function changeAddress(this:any, index:number, taskName: string) {
  if(this.state.clientView) return;
  const locations = this.state.locations
  this.activeIndex = index
  this.setState({addressPopupOpened: true}, () => {
    this.address.value = locations[index].address
    let autocomplete = new window.google.maps.places.Autocomplete(this.address)
    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace()
        locations[index].lat = place.geometry.location.lat()
        locations[index].lng = place.geometry.location.lng()
        locations[index].address = place.formatted_address
        this.setState({locations, addressPopupOpened: false},() => {
          this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, locations);
        })
    });
  })
}
