import * as React from "react";
import { Link } from "react-router-dom";
import "./Tasks.css";
import * as taskController from "../task/controller";
import { TaskStatus } from "../../_models/Task";
import * as ReactSwipe from "react-swipe";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { customSelectStyles, themeStyles } from "../task/themeStyles.js";
import Select from "react-select";

export default class Tasks extends React.Component<any, any> {
  removeTask: any;
  loadTasks: any;
  reactSwipeEl: any;
  updateSliderIndex: any;
  toggleTasksView: any;
  table: any;
  constructor(props: any) {
    super(props);
    this.state = {
      unassigned: [],
      assigned: [],
      active: [],
      completed: [],
      slideIndex: 0,
      tableView: false,
    };
    this.loadTasks = props.loadTasks.bind(this);
    this.updateSliderIndex = props.updateSliderIndex.bind(this);
    this.removeTask = taskController.removeTask.bind(this);
    this.toggleTasksView = props.toggleTasksView.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        tableView: this.props.tableView,
        slideIndex: this.props.sliderIndex,
      },
      async () => {
        await this.loadTasks();
        const scroll = localStorage.getItem("scrollPosition");
        if (scroll) {
          if(this.state.tableView) {
            this.table?.scrollTo(0, parseFloat(scroll))
          } else {
            window.scrollTo(0, parseFloat(scroll));
          }
        }
        if(this.state.tableView) {
          this.table?.addEventListener("touchmove", this.scrollFunction, false);
          this.table?.addEventListener("scroll", this.scrollFunction);
        } else {
          document.addEventListener("touchmove", this.scrollFunction, false);
          document.addEventListener("scroll", this.scrollFunction);
        }
      }
    );



  }

  scrollFunction = () => {
    if(this.state.tableView) {
      localStorage.setItem("scrollPosition", this.table?.scrollTop.toString());
    } else {
      localStorage.setItem("scrollPosition", window.scrollY.toString());

    }
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        unassigned: nextProps.tasksArrays[0],
        assigned: nextProps.tasksArrays[1],
        active: nextProps.tasksArrays[2],
        completed: nextProps.tasksArrays[3],
        slideIndex: nextProps.sliderIndex,
        tableView: nextProps.tableView,
      },
      async () => {
        if(this.state.tableView) {
          this.table = document.getElementsByClassName("rt-tbody")[0];
          this.table?.addEventListener("scroll", this.scrollFunction);
          this.table?.addEventListener("touchmove", this.scrollFunction, false);
        }
        const scroll = localStorage.getItem("scrollPosition");
        if (scroll) {
          if(this.state.tableView) {
            this.table?.scrollTo(0, parseFloat(scroll))
          } else {
            window.scrollTo(0, parseFloat(scroll));
          }
        }
      }
    );
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollFunction);
    document.removeEventListener("touchmove", this.scrollFunction);
    this.table?.addEventListener("scroll", this.scrollFunction);
    this.table?.removeEventListener("touchmove", this.scrollFunction);
  }

  renderTasks = (array: string) => {
    let tasks: any[] = [];
    for (let i = 0; i < this.state[array].length; i++) {
      let task = Object.assign({}, this.state[array][i]); //Doing this to prevent editing the tasks in the view
      let title = task.po;
      let borderClass =
        task.status == TaskStatus.completed && !task.endTime
          ? "stillActive"
          : "";
      tasks.push(
        <div
          key={i}
          className={task.published ? "task " + borderClass : "task draft"}
        >
          {!task.published && <h5>Draft</h5>}
          <i
            onClick={this.removeTask.bind(this, task.id, task.status)}
            className="fas fa-trash"
          ></i>
          <Link to={"/tasks/" + task.id}>
            <h4>
              {task.id} : {title}
            </h4>
          </Link>
          <span>
            <span>Date</span>: {task.date} - {task.startTime}
          </span>
          {task.client && (
            <span>
              <span>Client</span>:{" "}
              {task.client.name +
                (task.subClient ? " | " + task.subClient.name : "")}
            </span>
          )}
          {task.provider && (
            <span>
              <span>Provider</span>:{" "}
              {task.provider.name +
                (task.subProvider ? " | " + task.subProvider.name : "")}
            </span>
          )}
        </div>
      );
    }
    return tasks;
  };

  public render() {
    const taskStatus = [
      { label: "Unassigned", value: 0 },
      { label: "Assigned", value: 1 },
      { label: "Active", value: 2 },
      { label: "Completed", value: 3 },
    ];
    const columns = [
      {
        Header: "#",
        accessor: "id",
        maxWidth: 80,
        resizable: false,
      },
      {
        id: "provider",
        Header: "Provider",
        maxWidth: 120,
        accessor: (d: any) => d,
        Cell: (row: any) => (
          <div>{row.value.provider ? row.value.provider.name : ""}</div>
        ),
      },
      {
        id: "client",
        Header: "Client",
        maxWidth: 160,
        accessor: (d: any) => d,
        Cell: (row: any) => (
          <div>{row.value.client ? row.value.client.name : ""}</div>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        maxWidth: 140,
      },
      {
        Header: "Start Time",
        maxWidth: 120,
        accessor: "startTime",
      },
      {
        Header: "End Time",
        maxWidth: 120,
        accessor: "endTime",
      },
      {
        Header: "P.O.",
        accessor: "po",
      },
      {
        id: "settings",
        Header: "Settings",
        sortable: false,
        maxWidth: 100,
        resizable: false,
        accessor: (d: any) => d,
        Cell: (row: any) => (
          <div>
            <Link to={"/tasks/" + row.value.id}>
              <i className="fas fa-edit"></i>
            </Link>
            <i
              onClick={taskController.removeTask.bind(this, row.value.id, row.value.status)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ),
      },
    ];
    return (
      <div id="tasks-root">
        <div className="board">
          {window.innerWidth <= 768 ? (
            <ReactSwipe
              className="carousel"
              swipeOptions={{
                startSlide: this.state.slideIndex,
                continuous: false,
                transitionEnd: (index: any, element: any) => {
                  this.updateSliderIndex(index);
                },
              }}
              ref={(el) => (this.reactSwipeEl = el)}
            >
              <div className="column">
                <div className="columnHeader unassigned">Unassigned</div>
                {this.renderTasks(TaskStatus.unassigned)}
              </div>
              <div className="column">
                <div className="columnHeader assigned">Assigned</div>
                {this.renderTasks(TaskStatus.assigned)}
              </div>
              <div className="column">
                <div className="columnHeader active">Active</div>
                {this.renderTasks(TaskStatus.active)}
              </div>
              <div className="column">
                <div className="columnHeader completed">Completed</div>
                {this.renderTasks(TaskStatus.completed)}
              </div>
            </ReactSwipe>
          ) : (
            <div>
              <i
                className={
                  this.state.tableView ? "fab fa-trello" : "fas fa-table"
                }
                onClick={this.toggleTasksView}
              ></i>
              {this.state.tableView ? (
                <div>
                  <div className="console">
                    <div className="third">
                      <label>Status</label>
                      <Select
                        styles={customSelectStyles}
                        value={taskStatus[this.state.slideIndex]}
                        onChange={(selectedOption: any) => {
                          this.setState(
                            { slideIndex: selectedOption.value },
                            () => {
                              this.updateSliderIndex(selectedOption.value);
                            }
                          );
                        }}
                        options={taskStatus}
                        theme={themeStyles}
                        className={"multiSelect searchSelect"}
                        classNamePrefix="react-select"
                        menuPlacement={"bottom"}
                        placeholder={"Change Status"}
                      />
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <ReactTable
                    data={
                      this.state[
                        taskStatus[this.state.slideIndex].label.toLowerCase()
                      ]
                    }
                    columns={columns}
                    noDataText="No tasks found"
                    pageSizeOptions={[100]}
                    defaultPageSize={100}
                    style={{
                      height: "calc(100vh - 190px)",
                      textAlign: "center",
                      fontSize: "1em",
                      position: "centner",
                      right: "0",
                      left: "0",
                      margin: "0 auto",
                    }}
                    className="-striped -highlight reactTable"
                  />
                </div>
              ) : (
                <div>
                  <div className="column">
                    <div className="columnHeader unassigned">Unassigned</div>
                    {this.renderTasks(TaskStatus.unassigned)}
                  </div>
                  <div className="column">
                    <div className="columnHeader assigned">Assigned</div>
                    {this.renderTasks(TaskStatus.assigned)}
                  </div>
                  <div className="column">
                    <div className="columnHeader active">Active</div>
                    {this.renderTasks(TaskStatus.active)}
                  </div>
                  <div className="column">
                    <div className="columnHeader completed">Completed</div>
                    {this.renderTasks(TaskStatus.completed)}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
