export const TaskStatus = Object.freeze({
  "unassigned": "unassigned",
  "assigned": "assigned",
  "active": "active",
  "completed": "completed"
})
export const TaskTypes = Object.freeze({
  "unassigned": "unassigned",
  "assigned": "assigned",
  "active": "active",
  "completed": "completed"
})

import * as Moment from 'moment-timezone'

export type ServerTGeo = {
  //coordinates: [lng, lat]
  geo: { type: "Point", coordinates: [number, number] }
  taskId: number
  velocity: number
  createdAt: string
}

export type TGeo = {
  lat: number
  lng: number
  taskId: number
  velocity: number
  createdAt: string
  carAngle: number
}

export class Task {
  public name: string = "task"
  public id?: number
  public type: number
  public status: string
  public date: string
  public startTime: string
  public endTime: string
  public client: any
  public provider: any
  public subProvider: any
  public subClient: any
  public project: any
  public addresses: any[]
  public pickup: any[]
  public dropoff: any[]
  public middle: any[]
  public notifications: any[]
  public updated: boolean
  public notes: any[]
  public description: string
  public po: string
  public car: any
  public user: any
  public locations: any[]
  public signatures: any[]
  public images: any[]
  public published: boolean
  public exported: boolean
  public archived: boolean
  public trackUrl: string
  public rawStartTime: number
  public taskGeos?: TGeo[]

  //rates
  public clientHourRate: number
  public clientFlatRate: number
  public clientPRate: number
  public providerHourRate: number
  public providerFlatRate: number
  public providerPRate: number
  public clientTolls: number
  public clientTip: number
  public providerTolls: number
  public providerTip: number

  //reminders
  public hourReminder: boolean
  public dayReminder: boolean

  public rating: number
  public backOffice: string;

  public clientId: number;
  public providerId: number;

  constructor(task: any = {}) {
    this.id = task.id || null
    this.type = task.type || null
    this.status = task.status || TaskStatus.unassigned
    this.date = task.date ? Moment.tz(task.date, process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY") : Moment().tz(process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY")
    this.rawStartTime = new Date(task.startTime).getTime()
    this.startTime = task.startTime ? Moment.tz(task.startTime, process.env.REACT_APP_TIMEZONE || "").format("hh:mm A") : ""
    this.endTime = task.endTime ? Moment.tz(task.endTime, process.env.REACT_APP_TIMEZONE || "").format("hh:mm A") : ""
    this.client = task.client || null
    this.provider = task.provider || null
    this.subProvider = task.subProvider || null
    this.subClient = task.subClient || null
    this.project = task.project || null
    this.addresses = (task.pickup && task.pickup.length) ? task.pickup?.map((a:any)=>({...a, orderId: a.orderId || a.id})).concat(task.middle?.map((a:any)=>({...a, orderId: a.orderId || a.id})).concat(task.dropoff?.map((a:any)=>({...a, orderId: a.orderId || a.id})))) : [{type: "pickup", name: "", address: "", orderId: 0}]
    this.pickup = (task.pickup && task.pickup.length) ? task.pickup?.map((a:any)=>({...a, orderId: a.orderId || a.id})) : [{type: "pickup", name: "", address: "", orderId: 0}]
    this.dropoff = (task.dropoff && task.dropoff.length) ? task.dropoff?.map((a:any)=>({...a, orderId: a.orderId || a.id})) : [{type: "dropoff", name: "", address: "", orderId: 0}]
    this.middle = (task.middle && task.middle.length) ? task.middle?.map((a:any)=>({...a, orderId: a.orderId || a.id})) : []
    this.description = task.description || ""
    this.po = task.po || ""
    this.backOffice = task.backOffice || ""
    this.notifications = task.notifications || []
    this.updated = task.updated || true,
      this.car = task.car || null
    this.user = task.user || null
    this.locations = task.taskLocations || []
    this.signatures = task.taskSignatures || []
    this.images = task.taskImages || []
    this.trackUrl = task.trackUrl || null

    this.clientHourRate = task.clientHourRate || 0
    this.clientFlatRate = task.clientFlatRate || 0
    this.clientPRate = task.clientPRate || 0
    this.clientTolls = task.clientTolls || 0
    this.clientTip = task.clientTip || 0

    this.providerHourRate = task.providerHourRate || 0
    this.providerFlatRate = task.providerFlatRate || 0
    this.providerPRate = task.providerPRate || 0
    this.providerTolls = task.providerTolls || 0
    this.providerTip = task.providerTip || 0

    this.hourReminder = task.hourReminder || false
    this.dayReminder = task.dayReminder || false
    this.rating = task.rating || 0

    this.clientId = task.clientId || 0
    this.providerId = task.providerId || 0

    this.published = task.published || false
    this.exported = task.exported || false
    this.archived = task.archived || false
    this.taskGeos = task.taskGeos?.map((tg: ServerTGeo) => ({
      taskId: tg.taskId,
      createdAt: Moment.tz(tg.createdAt, process.env.REACT_APP_TIMEZONE || "").format("MM/DD/YYYY hh:mm A"),
      velocity: tg.velocity,
      lng: tg?.geo?.coordinates[0],
      lat: tg?.geo?.coordinates[1],
    })) || []
  }
}
